import { useActorFromSystem } from "~/state/system";
import { CurrentUserActor } from "./actor";
import { Profile } from "@fscrypto/domain/profile";
import { CurrentUser, UserUpdate } from "@fscrypto/domain/user";
import { useMatches } from "@remix-run/react";
import { PlanSettings } from "@fscrypto/domain/plan-settings";

export const useCurrentUser = () => {
  const [state, ref] = useActorFromSystem<CurrentUserActor>("currentUser");
  const matches = useMatches();

  // if the machine isn't ready yet, we can use the matches to get the data
  if (!state || !ref || !state.context.currentUser) {
    const match = matches.find((match) => (match.id = "root")) as any;
    return {
      currentUser: match?.data?.currentUser as CurrentUser,
      profile: match?.data?.profile as Profile,
      userPlan: match?.data?.userPlan as PlanSettings,
    } as const;
  }

  return {
    profile: state.context.profile,
    userPlan: state.context.userPlan,
    currentUser: state.context.currentUser,
    currentContext:
      state.context.currentUser?.currentProfileId === state.context.currentUser?.profileId ? "user" : "team",
    errors: state.context.errors,
    setDefaultWarehouse: (defaultWarehouseType: Profile["defaultWarehouseType"]) =>
      ref.send({ type: "CURRENT_USER.SET_DEFAULT_WAREHOUSE", payload: defaultWarehouseType }),
    saveProfile: (payload: UserUpdate) => ref.send({ type: "CURRENT_USER.UPDATE_PROFILE", payload }),
    isUpdatingUser: state.matches("updatingUser"),
    setCurrentProfileId: (profileId: string) =>
      ref.send({ type: "CURRENT_USER.SET_CURRENT_PROFILE_ID", payload: { profileId } }),
  };
};
